import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import Fade from "react-reveal/Fade"
import Box from "common/components/Box"
import Text from "common/components/Text"
import Heading from "common/components/Heading"
import Card from "common/components/Card"
import GatsbyImage from "common/components/GatsbyImage"
import FeatureBlock from "common/components/FeatureBlock"
import Container from "common/components/UI/Container"
import iconlogo from "common/assets/image/appModern/branding book updated -31.png"
import QualitySectionWrapper from "./qualitySection.style"
import { isMobile } from "react-device-detect"
import { ArabicNumbers } from "react-native-arabic-numbers"

const QualitySection = ({
  row,
  col,
  title,
  featureCol,
  description,
  textArea,
  imageArea,
  imageAreaRow,
  imageWrapper,
  featureTitle,
  featureDescription,
  iconStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      agencyJson {
        qualityFeature {
          description
          icon
          id
          title
        }
      }
      image: file(relativePath: { eq: "image/agency/surface-studio.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  return (
    <QualitySectionWrapper id="qualitySection" dir="rtl" lang="ar">
      <Container>
        <Box className="row" dir="rtl" lang="ar">
          <Box className="col-12" dir="rtl" lang="ar">
            <FeatureBlock
              title={
                <Heading
                  content="التفسير والتعاريف"
                  {...title}
                  style={{ color: "#f6c04d", textAlign: "center" }}
                />
              }
              description={
                <>
                  <Box className="col-12">
                    <FeatureBlock
                      title={
                        <Heading
                          style={{ color: "#f6c04d", textAlign: "center" }}
                          content={
                            <>
                              <img
                                src={iconlogo}
                                style={{ width: isMobile ? "6vw" : "2vw" }}
                              ></img>
                              <span style={{ fontSize: "28px" }}>تفسير:</span>
                            </>
                          }
                          {...title}
                        />
                      }
                      description={
                        <Text
                          style={{ color: "#fff" }}
                          content="الكلمات التي يتم كتابة الحرف الأول منها بأحرف كبيرة لها معاني محددة في ظل الشروط التالية. يكون للتعاريف التالية نفس المعنى بغض النظر عما إذا كانت تظهر بصيغة المفرد أو الجمع."
                          {...description}
                        />
                      }
                    />
                  </Box>
                  <Box className="col-12">
                    <FeatureBlock
                      title={
                        <Heading
                          style={{ color: "#f6c04d", textAlign: "center" }}
                          content={
                            <>
                              <img
                                src={iconlogo}
                                style={{ width: isMobile ? "6vw" : "2vw" }}
                              ></img>
                              <span style={{ fontSize: "28px" }}>
                                التعاريف:
                              </span>
                            </>
                          }
                          {...title}
                        />
                      }
                      description={
                        <>
                          <Text
                            style={{ color: "#fff", fontSize: "22px" }}
                            content="- لأغراض سياسة الخصوصية هذه:"
                            {...description}
                          />
                          <Text
                            style={{ color: "#fff", whiteSpace: "pre-wrap" }}
                            content={
                              <>
                                <p
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                >
                                  - الحساب:
                                  <span
                                    style={{
                                      color: "#fff",
                                    }}
                                  >
                                    {
                                      " يعني حسابا فريدا تم إنشاؤه لك للوصول إلى خدمتنا أو أجزاء من خدمتنا."
                                    }
                                  </span>
                                </p>
                                <p
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                >
                                  - الشركة التابعة :
                                  <span
                                    style={{
                                      color: "#fff",
                                    }}
                                  >
                                    {
                                      <>
                                        تعني الكيان الذي يتحكم أو يتحكم فيه أو
                                        يخضع لسيطرة مشتركة مع طرف، حيث تعني
                                        "السيطرة" ملكية 50٪ أو أكثر من الأسهم أو
                                        حقوق الملكية أو الأوراق المالية الأخرى
                                        التي يحق لها التصويت لانتخاب أعضاء مجلس
                                        الإدارة أو سلطة إدارية أخرى.
                                      </>
                                    }
                                  </span>
                                </p>
                                <p
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                >
                                  - التطبيق :
                                  <span
                                    style={{
                                      color: "#fff",
                                    }}
                                  >
                                    {
                                      "يشير التطبيق إلى Dayra، البرنامج الذي توفره الشركة."
                                    }
                                  </span>
                                </p>
                                <p
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                >
                                  - الشركة :
                                  <span
                                    style={{
                                      color: "#fff",
                                    }}
                                  >
                                    {
                                      <>
                                        تشير الشركة (المشار إليها باسم "الشركة"
                                        أو "نحن" أو "نحن" أو "خاصتنا" في هذه
                                        الاتفاقية) إلى نيون، 13 شارع أحمد فؤاد،
                                        النزهة، مصر الجديدة، القاهرة، مصر.
                                      </>
                                    }
                                  </span>
                                </p>
                                <p
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                >
                                  - البلد :
                                  <span
                                    style={{
                                      color: "#fff",
                                    }}
                                  >
                                    {"  يشير إلى: مصر"}
                                  </span>
                                </p>
                                <p
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                >
                                  - الجهاز :
                                  <span
                                    style={{
                                      color: "#fff",
                                    }}
                                  >
                                    {
                                      " يعني أي جهاز يمكنه الوصول إلى الخدمة مثل جهاز كمبيوتر أو هاتف محمول أو جهاز لوحي رقمي."
                                    }
                                  </span>
                                </p>
                                <p
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                >
                                  - البيانات الشخصية :
                                  <span
                                    style={{
                                      color: "#fff",
                                    }}
                                  >
                                    {
                                      "  هي أي معلومات تتعلق بفرد محدد أو يمكن التعرف عليه."
                                    }
                                  </span>
                                </p>
                                <p
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                >
                                  - الخدمة :
                                  <span
                                    style={{
                                      color: "#fff",
                                    }}
                                  >
                                    {" تشير الخدمة إلى التطبيق."}
                                  </span>
                                </p>
                                <p
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                >
                                  - مقدم الخدمة :
                                  <span
                                    style={{
                                      color: "#fff",
                                    }}
                                  >
                                    {
                                      " يعني أي شخص طبيعي أو اعتباري يقوم بمعالجة البيانات نيابة عن الشركة. يشير إلى شركات أو أفراد تابعين لجهات خارجية توظفهم الشركة لتسهيل الخدمة، أو لتقديم الخدمة نيابة عن الشركة، أو لأداء الخدمات المتعلقة بالخدمة أو لمساعدة الشركة في تحليل كيفية استخدام الخدمة."
                                    }
                                  </span>
                                </p>
                                <p
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                >
                                  - خدمة الوسائط الاجتماعية التابعة لجهات خارجية
                                  :
                                  <span
                                    style={{
                                      color: "#fff",
                                    }}
                                  >
                                    {
                                      "  تشير خدمة الوسائط الاجتماعية التابعة لجهات خارجية إلى أي موقع ويب أو أي موقع ويب لشبكة اجتماعية يمكن للمستخدم من خلاله تسجيل الدخول أو إنشاء حساب لاستخدام الخدمة."
                                    }
                                  </span>
                                </p>
                                <p
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                >
                                  - بيانات الاستخدام :
                                  <span
                                    style={{
                                      color: "#fff",
                                    }}
                                  >
                                    {
                                      "  تشير بيانات الاستخدام إلى البيانات التي يتم جمعها تلقائيا، إما عن طريق استخدام الخدمة أو من البنية التحتية للخدمة نفسها (على سبيل المثال، مدة زيارة الصفحة)."
                                    }
                                  </span>
                                </p>
                                <p
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                >
                                  - أنت:
                                  <span
                                    style={{
                                      color: "#fff",
                                    }}
                                  >
                                    {
                                      " تعني الفرد الذي يصل إلى الخدمة أو يستخدمها، أو الشركة، أو الكيان القانوني الآخر الذي يقوم هذا الفرد بالوصول إلى الخدمة أو استخدامها نيابة عنه، حسب الاقتضاء."
                                    }
                                  </span>
                                </p>
                              </>
                            }
                            {...description}
                          />
                        </>
                      }
                    />
                  </Box>
                </>
              }
            />
          </Box>
          <Box className="col-12">
            <FeatureBlock
              title={
                <Heading
                  content="جمع واستخدام بياناتك الشخصية"
                  {...title}
                  style={{ color: "#f6c04d", textAlign: "center" }}
                />
              }
              description={
                <>
                  <Box className="col-12">
                    <FeatureBlock
                      title={
                        <Heading
                          style={{ color: "#f6c04d", textAlign: "center" }}
                          content={
                            <>
                              <img
                                src={iconlogo}
                                style={{ width: isMobile ? "6vw" : "2vw" }}
                              ></img>
                              <span style={{ fontSize: "28px" }}>
                                أنواع البيانات التي يتم جمعها
                              </span>
                            </>
                          }
                          {...title}
                        />
                      }
                      description={
                        <>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - البيانات الشخصية
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        أثناء استخدام خدمتنا، قد نطلب منك
                                        تزويدنا بمعلومات تعريف شخصية معينة يمكن
                                        استخدامها للاتصال بك أو التعرف عليك. قد
                                        تتضمن معلومات التعريف الشخصية، على سبيل
                                        المثال لا الحصر:
                                      </p>
                                      <ul>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" عنوان البريد الإلكتروني"}
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" رخصة المركبة (أمامية وخلفية)"}
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" رخصة القيادة (أمامية وخلفية)"}
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {
                                            " طراز السيارة وطرازها وعلامتها التجارية"
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" أرقام اللوحات"}
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" الاسم الأول واسم العائلة"}
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" الاسم الأول واسم العائلة"}
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {
                                            " العنوان، المحافظة، الرمز البريدي، المدينة"
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" بيانات الاستخدام"}
                                        </li>
                                      </ul>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - بيانات الاستخدام
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        يتم جمع بيانات الاستخدام تلقائيا عند
                                        استخدام الخدمة.
                                      </p>
                                      <p>
                                        قد تتضمن بيانات الاستخدام معلومات مثل
                                        عنوان بروتوكول الإنترنت الخاص بجهازك
                                        (مثل عنوان IP) ونوع المتصفح وإصدار
                                        المتصفح وصفحات خدمتنا التي تزورها ووقت
                                        وتاريخ زيارتك والوقت الذي تقضيه في تلك
                                        الصفحات ومعرفات الجهاز الفريدة وبيانات
                                        التشخيص الأخرى.
                                      </p>
                                      <p>
                                        عند الوصول إلى الخدمة عن طريق أو من خلال
                                        جهاز محمول، قد نقوم بجمع معلومات معينة
                                        تلقائيا، بما في ذلك، على سبيل المثال لا
                                        الحصر، نوع الجهاز المحمول الذي تستخدمه،
                                        والمعرف الفريد لجهازك المحمول، وعنوان IP
                                        لجهازك المحمول، ونظام تشغيل هاتفك
                                        المحمول، ونوع متصفح الإنترنت عبر الهاتف
                                        المحمول الذي تستخدمه، ومعرفات الجهاز
                                        الفريدة وبيانات التشخيص الأخرى.
                                      </p>
                                      <p>
                                        قد نقوم أيضا بجمع المعلومات التي يرسلها
                                        متصفحك كلما قمت بزيارة خدمتنا أو عند
                                        الوصول إلى الخدمة عن طريق أو من خلال
                                        جهاز محمول.
                                      </p>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - معلومات من خدمات وسائل التواصل
                                        الاجتماعي التابعة لجهات خارجية
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        معلومات من خدمات وسائل التواصل الاجتماعي
                                        التابعة لجهات خارجية:
                                      </p>
                                      <ul>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" جوجل"}
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" فيس بوك"}
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" انستغرام"}
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" تويتر"}
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" لينكد إن"}
                                        </li>
                                      </ul>
                                      <p>
                                        إذا قررت التسجيل من خلال خدمة وسائط
                                        اجتماعية تابعة لجهة خارجية أو منحنا حق
                                        الوصول إليها بطريقة أخرى، فقد نجمع
                                        بيانات شخصية مرتبطة بالفعل بحساب خدمة
                                        الوسائط الاجتماعية التابعة لجهة خارجية،
                                        مثل اسمك، أو عنوان بريدك الإلكتروني، أو
                                        أنشطتك، أو قائمة جهات الاتصال الخاصة بك
                                        المرتبطة بهذا الحساب.
                                      </p>
                                      <p>
                                        قد يكون لديك أيضا خيار مشاركة معلومات
                                        إضافية مع الشركة من خلال حساب خدمة
                                        الوسائط الاجتماعية التابعة لجهة خارجية.
                                        إذا اخترت تقديم هذه المعلومات والبيانات
                                        الشخصية، أثناء التسجيل أو غير ذلك، فأنت
                                        تمنح الشركة الإذن باستخدامها ومشاركتها
                                        وتخزينها بطريقة تتفق مع سياسة الخصوصية
                                        هذه.
                                      </p>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - المعلومات التي يتم جمعها أثناء استخدام
                                        التطبيق
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        أثناء استخدام تطبيقنا، من أجل توفير
                                        ميزات تطبيقنا، قد نجمع، بإذن مسبق منك:
                                      </p>
                                      <ul>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" معلومات تتعلق بموقعك"}
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {
                                            "معلومات من دفتر هاتف جهازك (قائمة جهات الاتصال)"
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {
                                            " الصور والمعلومات الأخرى من كاميرا جهازك ومكتبة الصور"
                                          }
                                        </li>
                                      </ul>
                                      <p>
                                        نستخدم هذه المعلومات لتوفير ميزات
                                        خدمتنا، لتحسين خدمتنا وتخصيصها. قد يتم
                                        تحميل المعلومات إلى خوادم الشركة و / أو
                                        خادم مزود الخدمة أو قد يتم تخزينها
                                        ببساطة على جهازك.
                                      </p>
                                      <p>
                                        يمكنك تمكين أو تعطيل الوصول إلى هذه
                                        المعلومات في أي وقت، من خلال إعدادات
                                        جهازك.
                                      </p>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - استخدام بياناتك الشخصية
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        يجوز للشركة استخدام البيانات الشخصية
                                        للأغراض التالية:
                                      </p>
                                      <ul>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • لتوفير خدمتنا والحفاظ عليها:
                                          </span>

                                          {
                                            "  بما في ذلك مراقبة استخدام خدمتنا."
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • لإدارة حسابك:
                                          </span>
                                          {
                                            " لإدارة تسجيلك كمستخدم للخدمة. يمكن أن تمنحك البيانات الشخصية التي تقدمها إمكانية الوصول إلى وظائف مختلفة للخدمة المتاحة لك كمستخدم مسجل."
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • لأداء العقد:
                                          </span>
                                          {
                                            " تطوير والامتثال والتعهد بعقد الشراء للمنتجات أو العناصر أو الخدمات التي اشتريتها أو أي عقد آخر معنا من خلال الخدمة."
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • للاتصال بك:
                                          </span>
                                          {
                                            " للاتصال بك عن طريق البريد الإلكتروني، أو المكالمات الهاتفية، أو الرسائل القصيرة، أو غيرها من أشكال الاتصالات الإلكترونية المماثلة، مثل الإشعارات الفورية لتطبيقات الهاتف المحمول فيما يتعلق بالتحديثات أو الاتصالات الإعلامية المتعلقة بالوظائف، المنتجات أو الخدمات المتعاقد عليها، بما في ذلك التحديثات الأمنية، عند الضرورة أو المعقول لتنفيذها."
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • لتزويدك:
                                          </span>
                                          {
                                            " بالأخبار والعروض الخاصة والمعلومات العامة حول السلع والخدمات والأحداث الأخرى التي نقدمها والتي تشبه تلك التي اشتريتها بالفعل أو استفسرت عنها ما لم تكن قد اخترت عدم تلقي هذه المعلومات."
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • لإدارة طلباتك:
                                          </span>
                                          {" لحضور وإدارة طلباتك إلينا."}
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • لنقل الأعمال:
                                          </span>
                                          {
                                            " قد نستخدم معلوماتك لتقييم، أو إجراء عملية دمج، أو تجريد، أو إعادة هيكلة، أو إعادة تنظيم، أو حل، أو بيع، أو نقل آخر لبعض، أو كل أصولنا، سواء كان ذلك كمنشأة مستمرة أو كجزء من الإفلاس أو التصفية أو إجراء مماثل، حيث تكون البيانات الشخصية التي نحتفظ بها حول مستخدمي خدمتنا من بين الأصول المنقولة."
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • لأغراض أخرى:
                                          </span>
                                          {
                                            " قد نستخدم معلوماتك لأغراض أخرى، مثل تحليل البيانات وتحديد اتجاهات الاستخدام وتحديد فعالية حملاتنا الترويجية وتقييم وتحسين خدماتنا ومنتجاتنا، وخدماتنا، وتسويقنا، وتجربتك."
                                          }
                                        </li>
                                      </ul>
                                      <p>
                                        قد نشارك معلوماتك الشخصية في الحالات
                                        التالية:
                                      </p>
                                      <ul>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • مع مزودي الخدمة:
                                          </span>
                                          {
                                            " قد نشارك معلوماتك الشخصية مع مزودي الخدمة لمراقبة وتحليل استخدام خدمتنا، للاتصال بك."
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • لنقل الأعمال:
                                          </span>
                                          {
                                            " يجوز لنا مشاركة أو نقل معلوماتك الشخصية فيما يتعلق أو أثناء المفاوضات بشأن أي اندماج، أو بيع أصول الشركة، أو تمويل، أو الاستحواذ على كل أو جزء من أعمالنا إلى شركة أخرى."
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • مع الشركات التابعة:
                                          </span>
                                          {
                                            " قد نشارك معلوماتك مع الشركات التابعة لنا، وفي هذه الحالة سنطلب من تلك الشركات التابعة احترام سياسة الخصوصية هذه. تشمل الشركات التابعة شركتنا الأم وأي شركات تابعة أخرى أو شركاء في المشاريع المشتركة أو شركات أخرى نسيطر عليها أو تخضع لسيطرة مشتركة معنا."
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • مع شركاء الأعمال:
                                          </span>
                                          {
                                            "قد نشارك معلوماتك مع شركائنا في العمل لنقدم لك منتجات أو خدمات أو عروض ترويجية معينة."
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • مع مستخدمين آخرين:
                                          </span>
                                          {
                                            " عندما تشارك معلومات شخصية أو تتفاعل بطريقة أخرى في المناطق العامة مع مستخدمين آخرين، قد يتم عرض هذه المعلومات من قبل جميع المستخدمين وقد يتم توزيعها علنا في الخارج. إذا تفاعلت مع مستخدمين آخرين أو قمت بالتسجيل من خلال خدمة وسائط اجتماعية تابعة لجهة خارجية، فقد ترى جهات الاتصال الخاصة بك على خدمة الوسائط الاجتماعية التابعة لجهة خارجية اسمك وملفك الشخصي وصورك ووصف نشاطك. وبالمثل، سيتمكن المستخدمون الآخرون من عرض أوصاف نشاطك والتواصل معك وعرض ملفك الشخصي."
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            • بموافقتك:
                                          </span>
                                          {
                                            " يجوز لنا الكشف عن معلوماتك الشخصية لأي غرض آخر بموافقتك."
                                          }
                                        </li>
                                      </ul>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - الاحتفاظ ببياناتك الشخصية
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        ستحتفظ الشركة ببياناتك الشخصية فقط طالما
                                        كان ذلك ضروريا للأغراض المنصوص عليها في
                                        سياسة الخصوصية هذه. سنحتفظ ببياناتك
                                        الشخصية ونستخدمها بالقدر اللازم للامتثال
                                        لالتزاماتنا القانونية (على سبيل المثال،
                                        إذا طلب منا الاحتفاظ ببياناتك للامتثال
                                        للقوانين المعمول بها) ، وحل النزاعات ،
                                        وإنفاذ اتفاقياتنا وسياساتنا القانونية.
                                      </p>
                                      <p>
                                        ستحتفظ الشركة أيضا ببيانات الاستخدام
                                        لأغراض التحليل الداخلي. يتم الاحتفاظ
                                        ببيانات الاستخدام بشكل عام لفترة زمنية
                                        أقصر، إلا عندما يتم استخدام هذه البيانات
                                        لتعزيز الأمان أو لتحسين وظائف خدمتنا، أو
                                        نحن ملزمون قانونا بالاحتفاظ بهذه
                                        البيانات لفترات زمنية أطول.
                                      </p>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - نقل بياناتك الشخصية
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        تتم معالجة معلوماتك، بما في ذلك البيانات
                                        الشخصية، في مكاتب تشغيل الشركة وفي أي
                                        أماكن أخرى توجد فيها الأطراف المشاركة في
                                        المعالجة. هذا يعني أنه قد يتم نقل هذه
                                        المعلومات إلى - والاحتفاظ بها - على
                                        أجهزة الكمبيوتر الموجودة خارج ولايتك، أو
                                        مقاطعتك، أو بلدك، أو أي ولاية قضائية
                                        حكومية أخرى حيث قد تختلف قوانين حماية
                                        البيانات عن تلك الموجودة في ولايتك
                                        القضائية.
                                      </p>
                                      <p>
                                        موافقتك على سياسة الخصوصية هذه متبوعة
                                        بتقديمك لهذه المعلومات تمثل موافقتك على
                                        هذا النقل.
                                      </p>
                                      <p>
                                        ستتخذ الشركة جميع الخطوات اللازمة بشكل
                                        معقول لضمان التعامل مع بياناتك بشكل آمن
                                        ووفقا لسياسة الخصوصية هذه ولن يتم نقل
                                        بياناتك الشخصية إلى منظمة أو دولة ما لم
                                        تكن هناك ضوابط كافية في مكانها بما في
                                        ذلك أمان بياناتك والمعلومات الشخصية
                                        الأخرى.
                                      </p>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - حذف بياناتك الشخصية
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        لديك الحق في حذف أو طلب المساعدة في حذف
                                        البيانات الشخصية التي جمعناها عنك.
                                      </p>
                                      <p>
                                        قد تمنحك خدمتنا القدرة على حذف معلومات
                                        معينة عنك من داخل الخدمة.
                                      </p>
                                      <p>
                                        يمكنك تحديث معلوماتك أو تعديلها أو حذفها
                                        في أي وقت عن طريق تسجيل الدخول إلى
                                        حسابك، إذا كان لديك حساب، وزيارة قسم
                                        إعدادات الحساب الذي يسمح لك بإدارة
                                        معلوماتك الشخصية. يمكنك أيضا الاتصال بنا
                                        لطلب الوصول إلى أي معلومات شخصية قدمتها
                                        لنا أو تصحيحها أو حذفها.
                                      </p>
                                      <p>
                                        ومع ذلك، يرجى ملاحظة أننا قد نحتاج إلى
                                        الاحتفاظ بمعلومات معينة عندما يكون لدينا
                                        التزام قانوني أو أساس قانوني للقيام
                                        بذلك.
                                      </p>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                        </>
                      }
                    />
                  </Box>
                  <Box className="col-12">
                    <FeatureBlock
                      title={
                        <Heading
                          style={{ color: "#f6c04d", textAlign: "center" }}
                          content={
                            <>
                              <img
                                src={iconlogo}
                                style={{ width: isMobile ? "6vw" : "2vw" }}
                              ></img>
                              <span style={{ fontSize: "28px" }}>
                                الكشف عن بياناتك الشخصية
                              </span>
                            </>
                          }
                          {...title}
                        />
                      }
                      description={
                        <>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - المعاملات التجارية
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        إذا كانت الشركة متورطة في عملية دمج أو
                                        استحواذ أو بيع أصول، فقد يتم نقل بياناتك
                                        الشخصية. سنقدم إشعارا قبل نقل بياناتك
                                        الشخصية وتصبح خاضعة لسياسة خصوصية
                                        مختلفة.
                                      </p>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - انفاذ
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        في ظل ظروف معينة، قد يطلب من الشركة
                                        الكشف عن بياناتك الشخصية إذا طلب منها
                                        ذلك بموجب القانون أو استجابة لطلبات
                                        صالحة من قبل السلطات العامة (مثل محكمة
                                        أو وكالة حكومية).
                                      </p>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - المتطلبات القانونية الأخرى
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        يجوز للشركة الكشف عن بياناتك الشخصية
                                        بحسن نية أن هذا الإجراء ضروري من أجل:
                                      </p>
                                      <ul>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {"	الامتثال لالتزام قانوني"}
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {
                                            " حماية والدفاع عن حقوق أو ممتلكات الشركة"
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {
                                            " منع أو التحقيق في أي مخالفات محتملة فيما يتعلق بالخدمة"
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {
                                            "	حماية السلامة الشخصية لمستخدمي الخدمة أو الجمهور"
                                          }
                                        </li>
                                        <li>
                                          <span
                                            style={{
                                              color: "#f6c04d",
                                              fontSize: "15px",
                                            }}
                                          >
                                            •
                                          </span>
                                          {" 	الحماية من المسؤولية القانونية"}
                                        </li>
                                      </ul>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - أمن بياناتك الشخصية
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        يعد أمان بياناتك الشخصية أمرا مهما
                                        بالنسبة لنا، ولكن تذكر أنه لا توجد طريقة
                                        نقل عبر الإنترنت أو طريقة تخزين
                                        إلكترونية آمنة بنسبة 100٪. بينما نسعى
                                        جاهدين لاستخدام وسائل مقبولة تجاريا
                                        لحماية بياناتك الشخصية، لا يمكننا ضمان
                                        أمنها المطلق.
                                      </p>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - خصوصية الأطفال
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        لا تخاطب خدمتنا أي شخص يقل عمره عن 18
                                        عاما. نحن لا نجمع معلومات التعريف
                                        الشخصية عن قصد من أي شخص يقل عمره عن 18
                                        عاما. إذا كنت أحد الوالدين أو الوصي وكنت
                                        على علم بأن طفلك قد زودنا ببيانات شخصية،
                                        فيرجى الاتصال بنا. إذا علمنا أننا جمعنا
                                        بيانات شخصية من أي شخص يقل عمره عن 18
                                        عاما دون التحقق من موافقة الوالدين،
                                        فإننا نتخذ خطوات لإزالة هذه المعلومات من
                                        خوادمنا.
                                      </p>
                                      <p>
                                        إذا احتجنا إلى الاعتماد على الموافقة
                                        كأساس قانوني لمعالجة معلوماتك وكان بلدك
                                        يتطلب موافقة أحد الوالدين، فقد نطلب
                                        موافقة والديك قبل أن نجمع هذه المعلومات
                                        ونستخدمها.
                                      </p>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - روابط لمواقع أخرى
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        قد تحتوي خدمتنا على روابط لمواقع أخرى لا
                                        نقوم بتشغيلها. إذا قمت بالنقر فوق رابط
                                        جهة خارجية، توجيهك إلى موقع الطرف الثالث
                                        هذا. ننصحك بشدة بمراجعة سياسة الخصوصية
                                        لكل موقع تزوره.
                                      </p>
                                      <p>
                                        ليس لدينا أي سيطرة ولا نتحمل أي مسؤولية
                                        عن المحتوى أو سياسات الخصوصية أو
                                        الممارسات الخاصة بأي مواقع أو خدمات
                                        تابعة لجهات خارجية.
                                      </p>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                          <Box className="col-12">
                            <FeatureBlock
                              title={
                                <Heading
                                  style={{
                                    color: "#f6c04d",
                                  }}
                                  content={
                                    <>
                                      <span style={{ fontSize: "28px" }}>
                                        - التغييرات التي تطرأ على سياسة الخصوصية
                                        هذه
                                      </span>
                                    </>
                                  }
                                  {...title}
                                />
                              }
                              description={
                                <Text
                                  style={{ color: "#fff" }}
                                  content={
                                    <>
                                      <p>
                                        قد نقوم بتحديث سياسة الخصوصية الخاصة بنا
                                        من وقت لآخر. سنخطرك بأي تغييرات عن طريق
                                        نشر سياسة الخصوصية الجديدة على هذه
                                        الصفحة.
                                      </p>
                                      <p>
                                        سنخبرك عبر البريد الإلكتروني و / أو
                                        إشعار بارز على خدمتنا، قبل أن يصبح
                                        التغيير ساريا وتحديث تاريخ "آخر تحديث"
                                        في الجزء العلوي من سياسة الخصوصية هذه.
                                      </p>
                                      <p>
                                        ننصحك بمراجعة سياسة الخصوصية هذه بشكل
                                        دوري لأية تغييرات. تسري التغييرات التي
                                        تطرأ على سياسة الخصوصية هذه عند نشرها
                                        على هذه الصفحة.
                                      </p>
                                    </>
                                  }
                                  {...description}
                                />
                              }
                            />
                          </Box>
                        </>
                      }
                    />
                  </Box>
                </>
              }
            />
          </Box>
          <Box className="col-12">
            <FeatureBlock
              title={
                <Heading
                  style={{ color: "#f6c04d", textAlign: "center" }}
                  content={
                    <>
                      <img
                        src={iconlogo}
                        style={{ width: isMobile ? "8vw" : "3vw" }}
                      ></img>
                      <span>اتصل بنا</span>
                    </>
                  }
                  {...title}
                />
              }
              description={
                <Box className="col-12">
                  <FeatureBlock
                    title={
                      <Heading
                        style={{
                          color: "#fff",
                          textAlign: "center",
                          fontSize: "32px",
                        }}
                        content={
                          <>
                            -
                            <span>
                              لحل شكوى تتعلق بالخدمات أو لتلقي مزيد من المعلومات
                              حول استخدام الخدمات، يرجى الاتصال بنا على:
                            </span>
                          </>
                        }
                        {...title}
                      />
                    }
                    description={
                      <Text
                        style={{ color: "#fff", whiteSpace: "pre-wrap" }}
                        content={
                          <>
                            <p
                              style={{
                                color: "#f6c04d",
                              }}
                            >
                              - اسم الشركة:
                              <span
                                style={{
                                  color: "#fff",
                                }}
                              >
                                {" "}
                                {" نيون للخدمات الإعلانية والتكنولوجية"}
                              </span>
                            </p>
                            <p
                              style={{
                                color: "#f6c04d",
                              }}
                            >
                              - العنوان:
                              <span
                                style={{
                                  color: "#fff",
                                }}
                              >
                                {" "}
                                {
                                  "13 شارع أحمد فؤاد، النزهة، مصر الجديدة، القاهرة، مصر."
                                }
                              </span>
                            </p>
                            <p
                              style={{
                                color: "#f6c04d",
                              }}
                            >
                              - رقم الهانف:
                              <span
                                style={{
                                  color: "#fff",
                                }}
                              >
                                {" "}
                                {ArabicNumbers(201148866775)}+
                              </span>
                            </p>
                            <p
                              style={{
                                color: "#f6c04d",
                              }}
                            >
                              - البريد الإلكتروني:
                              <span
                                style={{
                                  color: "#fff",
                                }}
                              >
                                {" "}
                                {" supp.dayra@gmail.com"}
                              </span>
                            </p>
                          </>
                        }
                        {...description}
                      />
                    }
                  />
                </Box>
              }
            />
          </Box>
          // -------------------------------------------------------------------
        </Box>
        {/* <Box className="row" {...row} {...textArea}>
          {Data.agencyJson.qualityFeature.map((feature, index) => (
            <Box
              className="col"
              {...featureCol}
              key={`quality_feature-${index}`}
            >
              <FeatureBlock
                icon={<i className={feature.icon} />}
                iconPosition="left"
                iconStyle={iconStyle}
                title={<Heading content={feature.title} {...featureTitle} />}
                description={
                  <Text content={feature.description} {...featureDescription} />
                }
              />
            </Box>
          ))}
        </Box> */}
      </Container>
      {/* <Container fluid noGutter className="info-sec-container">
        <Box className="row" {...row} {...imageAreaRow}>
          <Box className="col" {...col} {...imageArea}>
            <Card {...imageWrapper}>
              <Fade right delay={90}>
                <GatsbyImage
                  src={
                    (Data.image !== null) | undefined
                      ? Data.image.childImageSharp.gatsbyImageData
                      : {}
                  }
                  alt="Feature Image"
                />
              </Fade>
            </Card>
          </Box>
        </Box>
      </Container> */}
    </QualitySectionWrapper>
  )
}

QualitySection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  featureCol: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
}

QualitySection.defaultProps = {
  // Quality section row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
  },
  // Quality section iamge row default style
  imageAreaRow: {
    flexDirection: "row-reverse",
  },
  // Quality section col default style
  col: {
    pr: "15px",
    pl: "15px",
  },
  // Quality feature col default style
  featureCol: {
    width: [1, 1, 1 / 2],
    pr: "15px",
    pl: "15px",
  },
  // Quality section text area default style
  textArea: {
    width: [1, "100%", "100%", "70%", "64%"],
  },
  // Quality section image area default style
  imageArea: {
    width: [1, "100%", "100%", "30%", "38%"],
    flexBox: true,
    flexDirection: "row-reverse",
  },
  // Quality section image wrapper default style
  imageWrapper: {
    boxShadow: "none",
    width: "100%",
  },
  // Quality section title default style
  title: {
    fontSize: ["26px", "26px", "32px", "40px"],
    lineHeight: "1.5",
    fontWeight: "300",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "20px",
  },
  // Quality section description default style
  description: {
    fontSize: "16px",
    color: "#343d48cc",
    lineHeight: "2.1",
    mb: "33px",
  },
  // feature icon default style
  iconStyle: {
    width: "54px",
    height: "54px",
    borderRadius: "50%",
    bg: "#93d26e",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "24px",
    color: "#ffffff",
    overflow: "hidden",
    mt: "6px",
    mr: "22px",
    flexShrink: 0,
  },
  // feature title default style
  featureTitle: {
    fontSize: ["18px", "20px"],
    fontWeight: "400",
    color: "#0f2137",
    lineHeight: "1.5",
    mb: "8px",
    letterSpacing: "-0.020em",
  },
  // feature description default style
  featureDescription: {
    fontSize: "15px",
    lineHeight: "1.84",
    color: "#343d48cc",
  },
}

export default QualitySection
